<template>
  <div id="app">
    <!-- <div class="Clist"  v-if="isShell">
        <el-dropdown trigger="click"  v-if="$route.path!='/login'">
            <div ><img src="@/assets/homeOnline/liebiao.png"></div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="iconfont iconshezhi" @click.native="menuClick('设置')">设置</el-dropdown-item>
              <el-dropdown-item @click.native="menuClick('查看更新')" icon="iconfont iconjianchagengxin">查看更新</el-dropdown-item>
              <el-dropdown-item @click.native="menuClick('关于我们')" icon="iconfont iconguanyuwomen">关于我们</el-dropdown-item>
              <el-dropdown-item @click.native="menuClick('退出')" icon="iconfont icontuichu">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
         <img src="@/assets/homeOnline/33.png" alt="" @click="AppMinimize()"> 
          <img src="@/assets/homeOnline/11.png" alt=""  @click="ApptoggleFullscreen()">
      <img src="@/assets/homeOnline/22.png" alt="" @click="AppClose()">
</div> -->
   <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
 </keep-alive>

 <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  data(){
    return{
    isShell:false
  }
  },
  created(){
   if(window.shell){
     this.isShell = true
   }

  }
  ,
  methods:{
    menuClick(type){
      if(type=='退出') {
        localStorage.removeItem('token')
        location.reload()
      }
     shell.OpenForm(type)
    },
        // 最小化窗口
      AppMinimize() {
        shell.Minimize();

      },
         ApptoggleFullscreen() {

        shell.Maximize();
      },
            // 关闭窗口
      AppClose(e) {
        shell.Close();
      },
  }
}
</script>

<style lang="less">
html,body,#app{
  height: 100%;
overflow: hidden;
}
// .Clist{
//   max-width: 300px;
//   z-index: 999999;
//   right: 30px;
// position: fixed;
// top: -10px;
// width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   img{
//     width: 20px ;
//     height: 20px;
//     cursor: pointer;
//     margin: 20px;
//   }
// }
.home{
  overflow-y: scroll;
  height: 100vh;
   &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(150, 150, 150, 0.66);
            border: 4px solid rgba(150, 150, 150, 0.66);
            border-radius: 5px;
            background-clip: content-box;
          }
}
*{
 margin: 0;
  padding: 0;
}
#app {
  font-size: .18rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-message{
  font-size: .16rem;
}
#nav {
 

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
