(function(doc,win){
    var docEI = doc.documentElement,
    resizeEvt = 'orientationchange' in window?'orientataionchange':'resize',
    recalc = function(){
        var clientWidth = docEI.clientWidth;
       
        if(!clientWidth) return;
        // 100是字体大小，1920是开发时浏览器窗口的宽度，等比计算
        if(clientWidth > 1500) {
            docEI.style.fontSize = 100*(clientWidth/1920)+'px';
        } else if ( clientWidth > 1000 && clientWidth < 1500 ) {
            docEI.style.fontSize = 130*(clientWidth/1920)+'px';
        } else {
            docEI.style.fontSize = 140*(clientWidth/1920)+'px';
        }
        // docEI.style.fontSize = 100*(clientWidth/1920)+'px';
    }

    if(!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document,window);
