import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    icon_down: true,
    userInfo: {},
    clazzObj: {},
    queryclazzlist: []
  },
  mutations: {
    // 切换上下屏
    icon_down(state, icon_down) {
      state.icon_down = icon_down
    },
    userInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    clazzObj(state, clazzObj) {
      state.clazzObj = clazzObj
    },
    queryclazzlist(state, queryclazzlist) {
      state.queryclazzlist = queryclazzlist
    },
  },
  actions: {},
  modules: {}
})