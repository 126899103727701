import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
  path: '/coursecenter',
  name: 'coursecenter',
  meta: {
    keepAlive: true,
  },
  component: () => import( /* webpackChunkName: "coursecenter" */ '@/views/home/coursecenter.vue')
},{
  path: '/',
  redirect: 'coursecenter'
}, {
  path: '/login',
  name: 'login',
  component: () => import( /* webpackChunkName: "login" */ '@/views/login/login.vue')
}, {
  path: '/home',
  name: 'home',
  meta: {
    keepAlive: true,
  },

  component: () => import( /* webpackChunkName: "index" */ '@/views/home/home.vue'),
  children: []
}, {
  path: '/study',
  name: 'study',
  meta: {

  },
  component: () => import( /* webpackChunkName: "study" */ '@/views/home/study.vue')
}]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('token') && to.path !== '/coursecenter' && to.path !== '/login' && to.path !== '/study') 
    next('/coursecenter')
  if (localStorage.getItem('token') && to.path == '/login') 
    next('/home')
  else 
    next();
})
export default router