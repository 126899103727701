import axios from 'axios'



let ENV = 'release'
let key, resKey
let posturl='';
let posturlOld='';
// axios.defaults.baseURL = 'https://apiv3-ceshi.eyyb.vip:444'//测试
if (ENV == 'dev') {
    posturlOld = 'https://apiv3-dev.eyyb.vip:444' //测试
    posturl = '' //测试
    key = 'GUiJwWoiilp4'
    resKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAp8YJFC0kgwvYJ7Uqp0dh+e8iplKYGC2bT+lPw0Pcku0uI/vyp3casbOB8y8ZtPkcB9pP2TE/EGBW/tSbIEBqEeTPsm/+Yofn5PUkAqFAn9uRSM+4Npp4z7PiaX6fgSshqWHK0f9IXbbM97k/wmoGQgNQQGt7WpqGRYe+teXInQIDAQAB'
}
if (ENV == 'ceshi') {
    posturlOld = 'https://apiv3-ceshi.eyyb.vip:444' //测试
    posturl = 'https://api-teacher-ceshi.eyyb.vip:444' //测试
    key = 'GUiJwWoiilp4'
    resKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCAp8YJFC0kgwvYJ7Uqp0dh+e8iplKYGC2bT+lPw0Pcku0uI/vyp3casbOB8y8ZtPkcB9pP2TE/EGBW/tSbIEBqEeTPsm/+Yofn5PUkAqFAn9uRSM+4Npp4z7PiaX6fgSshqWHK0f9IXbbM97k/wmoGQgNQQGt7WpqGRYe+teXInQIDAQAB'
}
if (ENV == 'stable') {
    posturlOld = 'https://apiv3-stable.eyyb.vip' //预发布
    posturl = 'https://api-teacher-stable.eyyb.vip' //预发布
    key = 'GUiJwW8bjn3j'
    resKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCOynL6f1PCKTxxq9aXE7QhaZFobuMlsTF/seW3JE9+9qXXzwAZCtt2mZAeTxYfSv93AHT/htQViSzVVV1kWnWpQpB8rVNCv7v5WlmdjGHZe0XTN0D+Rb9Mhd8Zy4ZU6ktE6tZDI+e4OqCjLugFejQIFfqMQf9Ha77gV67TeEL8QQIDAQAB'
}
if (ENV == 'release') {
    posturlOld = 'https://apiv3.eyyb.vip' //线上
    posturl = 'https://api-teacher.eyyb.vip' //线上
    key = 'GUiJwWng73hn'
    resKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvRo5cCb5ScxGQsYuf/63UQFrtMgbfzN42RIEmIxZArwIsM0/O1qjE+YCo/bdThlF9KkY8lzXetwssheRjpVdZVlAsdOQ2RyX7ip+H/0R0ddMJUWdDKeBA+B3yLE1X3QZ+xQ5SdjXzepzBYswP1b5cCvOSo4IoNdSGHDig5ck2YwIDAQAB'
}

axios.defaults.timeout = 1000 * 30

// 携带token
axios.interceptors.request.use(config => {
    config.headers.authorization = localStorage.getItem('token');
    return config;
}, error => {
    console.log(error)
})
export {
    key,
    resKey
}
// export function checkPCToken(actionUrl, callback) {
//     axios({
//             url: actionUrl,
//             method: 'POST',
//         })
//         .then((response) => {
//             if (response && response.data.code == 6125) {
//                 callback(response.data.msg)
//             }
//         })
//         .catch(function (err) {
//             console.log('数据请求失败,请稍后再试！', err);
//         });
// }
export function axiosPost(actionUrl, paramJson, sucCallback) {
    axios.defaults.baseURL =  posturlOld
    axios({
            url: actionUrl,
            method: 'POST',
            data: paramJson
        })
        .then(function (response) {

            sucCallback(response.data);

        })
        .catch(function (err) {
            console.log('数据请求失败,请稍后再试！',actionUrl+':'+err);
        });
}

export function axiosPostParams(actionUrl, paramJson, sucCallback) {
    axios.defaults.baseURL =  posturlOld
    axios({
            url: actionUrl,
            method: 'POST',
            params: paramJson
        })
        .then(function (response) {
            if (response.data.code == 0) {
                sucCallback(response.data);
            }


        })
        .catch(function (err) {
            console.log('数据请求失败,请稍后再试！',actionUrl+':'+err);
        });
}

export function axiosGet(actionUrl, paramJson, sucCallback) {
    if (paramJson && paramJson.localtype) {
        axios.defaults.baseURL = '' //线上
    }
    axios.defaults.baseURL =  posturl
    axios({
            url: actionUrl,
            method: 'GET',
            params: paramJson
        })
        .then(function (response) {
            sucCallback(response.data);
        })
        .catch(function (err) {
            console.log('数据请求失败,请稍后再试！' ,actionUrl+':'+err);
            if (JSON.stringify(err).indexOf('401') !== -1) {
                localStorage.removeItem('token')
                location.reload
            }
        });

}
export function axiosGetOld(actionUrl, paramJson, sucCallback) {
    if (paramJson && paramJson.localtype) {
        axios.defaults.baseURLold = '' //线上
    }
    axios.defaults.baseURL =  posturlOld
    axios({
            url: actionUrl,
            method: 'GET',
            params: paramJson
        })
        .then(function (response) {

            if (response.data.code == 0) {

                sucCallback(response.data);
            }
        })
        .catch(function (err) {
            console.log('数据请求失败,请稍后再试！',actionUrl+':'+err);
            if (JSON.stringify(err).indexOf('401') !== -1) {
                localStorage.removeItem('token')
                location.reload
            }
        });

}